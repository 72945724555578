.event-details-container {
  width: 100%;
}

.event-details-container h2 {
  margin-bottom: 16px;
  font-size: 22px;
}

.event-details-container p {
  margin: 12px 0;
  font-size: 1rem;
  line-height: 1.5;
}

.image-container {
  margin-bottom: 20px;
  padding-top: 40px;
}

.event-image {
  width: 50%;
  height: 600px;
}

.content-container {
  padding-left: 40px;
}
