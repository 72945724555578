.about-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.about-us-container {
  padding: 20px;
  margin: auto;
  max-width: 1000px;
}

.about-us-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #83214F;
}

.about-us-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.about-us-list {
  margin: 20px 0;
  padding-left: 20px;
}

.about-us-list li {
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about-us-container {
    padding: 15px;
  }

  .about-us-title {
    font-size: 18px;
  }

  .about-us-text {
    font-size: 0.9rem;
  }

  .about-us-list {
    padding-left: 15px;
  }
}

@media (max-width: 480px) {
  .about-us-title {
    font-size: 16px;
  }

  .about-us-text {
    font-size: 0.85rem;
  }

  .about-us-list {
    padding-left: 10px;
  }
}