.terms-container {
    padding: 20px;
    margin: auto;
    max-width: 1000px;
}

.terms-date { 
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
}

p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

ul ul {
    padding-left: 20px;
    margin-bottom: 10px;
}

li {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}

strong {
    color: #222;
}

/* Responsive Design */

/* Tablets and smaller devices */
@media (max-width: 768px) {
    .terms-container {
        padding: 15px;
    }

    p, li {
        font-size: 14px;
    }
}

/* Mobile screens (480px and below) */
@media (max-width: 480px) {
    .terms-container {
        padding: 10px;
    }

    p, li {
        font-size: 13px;
        line-height: 1.4;
    }

    .terms-date {
        font-size: 12px;
    }
}
