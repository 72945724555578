.home-bg-container {
  padding: 20px;
}

.each-carousel {
  margin-top: 0px;
}

.carousel-containerr {
  position: relative;
  margin-top: 5px;
}

.home-image {
  width: 100%;
  height: 77vh;
  object-fit: cover;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.homepage-bottom-container {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.content-container {
  width: 50%;
}

.card-image {
  height: 110px;
  width: 110px;
  border-radius: 20px;
}

.card-heading {
  font-size: 16px;
  font-weight: bolder;
  color: #faf1f8;
}

.card-content {
  font-size: 15px;
  color: #faf1f8;
}

.card-link {
  text-decoration: none;
  color: inherit;
  width: 40%;
}

.content-sub-container {
  display: flex;
  width: 95%;
  background-color: #83214f;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.content-main-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .home-image {
    height: 40vh;
    /* Reduced height on mobile devices */
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .home-image {
    height: 28vh;
    border-radius: 10px;
  }
}
